.bpn-container{
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  color: black !important;
}

.bpn-container table{
  width: 100%;
  border-collapse: collapse;
}

.bpn-header{
  text-align: center;
  text-transform: uppercase;
}
.bpn-header tr td{
  padding: 24px 0;
  border-bottom: thin solid black;
}
.bpn-header tr:first-child td.logo{
  width: 120px
}
.bpn-header tr:first-child td:nth-child(2) div:first-child{
  font-weight: bold;
  font-size: 22px;
}
.bpn-header tr:first-child td:nth-child(2) div:last-child{
  font-size: 18px;
  font-weight: bold;
}

.bpn-table tr td{padding: 4px 0;}
.bpn-table tr td:first-child{width: 180px;}
.bpn-table tr td:nth-child(2){width: 16px;}

.bpn-footer{
  text-align: center;
  font-style: italic;
  color: #888;
  margin-top: 24px
}

.background-primary{background: #f04438}
.color-primary{color: #f04438}

.v-gap{margin-bottom: 32px;}
.divider-label{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

/* #setoran-djp, #setoran-djbc, #setoran-dja{display: none} */
